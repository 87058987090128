@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(./fonts/CoolveticaRg-Regular.woff) format('woff');
  }

  html, body, #app, #app>div{
    height: 100%;
  } 
  #root{
    background-color: #F5FFFA;
  }

  .text{
    font-family: 'Helvetica', sans-serif;
    position: relative;
  }
  .text.black{
    color: black;
  }
  .text.white{
    color: white;
  }
  .text.disclaimer{
    font-size: xx-small;
    color:#842029;
    margin-top: 2%;
    margin-bottom: -2%;
  }
  .text.caption{
    text-align: center;
    padding-bottom: 2%;
  }
  .text.box{
    margin-top: 5%;
    font-weight: normal;
    text-align: center;
    line-height: 24px;
    color: rgb(44, 44, 44);
  }
  .footer{
    margin:auto;
    gap: 15px;
    position: relative;
  }

  .card-header, .card-footer{
    background-color: var(--mainColor) !important;
  }

  .projectBody{
    background-color: var(--backgroundComponentColor);
  }

  :root {
    --backgroundComponentColor:#fadbbe;
    --mainColor: #5073aa;
}

