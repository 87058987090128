.submitButton{
        justify-content: center;
        display: flex;
        align-items:center;
}
.carousel-indicators{
        bottom: 5%;
}

.form{
        background-color: var(--mainColor);
        padding: inherit;
        padding-bottom: 1%;
        border-radius: 5px 5px 5px 5px;
}