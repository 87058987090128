.copy-comp {
    position        : relative;
    background-color: #f2f3f3;
    margin          : auto;
    display         : flex;
    justify-content : flex-end;
    gap             : 2%;
    align-items     : center;
    border-radius   : 5px 5px 5px 5px;
    padding-left: 1%;
    max-width: fit-content;
}

.copy-button {
    flex-shrink: 0;
    flex-grow  : 1;
}

.copy-text {
    margin-left: 3%;
    text-decoration: none;
}

.btn:focus{
    box-shadow:none!important;
}



/*Small Devices*/
@media screen and (max-width: 425px) {
    .copy-comp {
        padding-left: 3%;
    }
}

