.App,
.Project {
    min-width : auto;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.projects {
    background-color: var(--backgroundComponentColor);
    margin-bottom: 4%;
}

.welcome {
    margin-top      : 1%;
    margin-bottom   : 4%;
    display         : flex;
    padding-top: 1%;
    flex-direction: column;
}

.welcome.video {
    margin: auto;
    width : 100%;
}

.cv{
    background-color: var(--backgroundComponentColor);
    margin-bottom: 4%;
}

.contact{
    background-color: var(--backgroundComponentColor);
    margin-bottom: 4%;
    padding-bottom: 1%;
    display:flex;
    flex-direction: column;
}