.boxes{
    overflow: hidden;
    border-radius: 5px 5px 5px 5px;
    top: 0px;
    left:0px;
    position: relative;
    padding: inherit;
    margin-bottom: 3%;
}
.boxes:hover{
    transform: scale(1.04) ;
}



.emptyImageProj{
    box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
    z-index: 1;
    position: relative;
    
}
.imageProj{
    position: absolute;
    top:0px;
    left: 0px;
    bottom: 0px;
    right: 0;
    z-index: 0;
    transition: all 1.5s ease;
    transform-origin: left;
    transform: scale(1.5);
    
}

.imageProj.normalSize{
    transform: scale(1.0);
}
.imageProj:hover .imageProj.normalSize{
    transform: scale(1.5);
}



.textBox {
    position: absolute;
    overflow-wrap: break-word;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    font-family: Helvetica,sans-serif;
    color: ivory;
}

.textBox.projName{
    width: 44%;
    max-height: 96%;
    top: 2%;
    left: 53%;
}

.textBox.shortProjDescription{
    width: 59%;
    max-height: 54%;
    top: 44%;
    left: 38%;
}
/*Small Devices*/
@media screen and (min-width: 320px)  {
    .textBox.projName {
        font-size: large;        
    }
    .textBox.shortProjDescription{
        display: none;
    }
}
/*Tablets*/
@media screen and (min-width: 768px)  {
    .textBox.projName{
        font-size: x-large;
        max-height: 40%;
    }

    .textBox.shortProjDescription{
        max-height: 46%;
        top: 50%;
        font-size: large;
        display: unset;
    }
}
/*Laptops*/
@media screen and (min-width: 992px) {
    .textBox.projName{
        font-size: x-large;
    }
    .textBox.shortProjDescription{
        font-size: large;
    }
}
/*Desktops*/
@media screen and (min-width: 1200px)  {
    .textBox.projName{
        font-size: x-large;
    }
    .textBox.shortProjDescription{
        font-size: medium;
    }

@media screen  and (min-width: 2560px) {
    .textBox.projName{
        font-size: xx-large;
    }
    .textBox.shortProjDescription{
        font-size: x-large;
    }
}
}


